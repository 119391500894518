import { HeaderAppearanceType, HeaderTab } from '@aether/models'
import { FC, useEffect, useRef, useState } from 'react'
import { Link } from '@aether/components'
import {
  safePolygon,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react'
import { styled } from '@aether/styles'
import { CommonTab } from './HeaderTab'
import { useRouter } from 'next/router'

const StyledLink = styled(Link, {
  display: 'inline-flex',
  $aetherFont: 'ui02',
  lineHeight: 1,
  borderRadius: '$r1',
  height: '$buttonHeightS',
  padding: '0 $20',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'background .4s, color .4s',
  '&:hover': {
    background: '$black',
    color: '$white',
  },
  '&:focus-visible': {
    background: '$black',
    color: '$white',
    outline: '$blue solid 3px',
    outlineOffset: '2px',
  },
  variants: {
    isOpen: {
      true: {
        background: '$black',
        color: '$white',
      },
    },
  },
})
const DrawerWrap = styled('div', {
  '@lg': {
    display: 'grid',
  },
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  height: 'auto',
  variants: {
    appearance: {
      journal: {
        backgroundColor: '$beige',
      },
      default: {
        backgroundColor: '$white',
      },
    },
    open: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
})

const TabWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  transition: 'opacity 200ms',
  display: 'grid',
})

const StyledLi = styled('li', {
  display: 'grid',
})

const ItemHoverableArea = styled('div', {
  padding: '0 $8',
})

export const DesktopMenuItem: FC<{
  tab: HeaderTab
  appearance: HeaderAppearanceType
}> = ({ tab, appearance }) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const tabLinksRefArray = useRef<Array<HTMLLinkElement | null>>([])

  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const handleClose = () => {
    setOpen(false)
  }

  const { context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useHover(context, {
        handleClose: safePolygon(),
      }),
      useFocus(context, {
        visibleOnly: true,
      }),
      useDismiss(context, {
        ancestorScroll: true,
      }),
      useListNavigation(context, {
        listRef: tabLinksRefArray,
        onNavigate: setActiveIndex,
        activeIndex,
        orientation: 'both',
        focusItemOnHover: false,
      }),
      useRole(context, {
        role: 'menu',
      }),
    ],
  )

  return (
    <StyledLi>
      <ItemHoverableArea
        {...getReferenceProps({
          ref: refs.setReference,
          onKeyDown(e) {
            if (e.key === 'Tab') {
              setOpen(false)
            }
          },
        })}
      >
        <StyledLink
          isOpen={open}
          key={tab.title}
          {...tab.link}
          appearance={'grid'}
        >
          {tab.title}
        </StyledLink>
      </ItemHoverableArea>
      {open && (
        <DrawerWrap
          open={open}
          ref={refs.setFloating}
          {...getFloatingProps({
            onKeyDown(e) {
              if (e.key === 'Tab') {
                setOpen(false)
              }
            },
          })}
          appearance={appearance}
        >
          <TabWrap key={tab.title}>
            <CommonTab
              tab={tab}
              activeIndex={activeIndex}
              getItemProps={getItemProps}
              listRef={tabLinksRefArray}
              handleClose={handleClose}
            />
          </TabWrap>
        </DrawerWrap>
      )}
    </StyledLi>
  )
}
