import { AnchorTextResult, TextResults } from '@aether/search/ui-text-results'
import { useHits } from 'react-instantsearch-hooks-web'

export const PagesHits = () => {
  const { hits } = useHits()

  const results: AnchorTextResult[] = hits.map((hit) => ({
    type: 'anchor',
    label: hit.title as string,
    href: hit.path as string,
  }))

  return <TextResults title="Pages" results={results} />
}
