import { CallbackTextResult, TextResults } from '@aether/search/ui-text-results'
import { useHits } from 'react-instantsearch-hooks-web'

interface SuggestionsHitsProps {
  onHitClick: (value: string) => void
}

export const SuggestionsHits = ({ onHitClick }: SuggestionsHitsProps) => {
  const { hits, results: hitsResults } = useHits()

  const results: CallbackTextResult[] = hits
    // we want to filter out suggestions that are the same as query
    .filter(
      (hit) => hit.objectID.toLowerCase() !== hitsResults?.query.toLowerCase(),
    )
    .map((hit) => ({
      type: 'callback',
      label: hit.objectID,
      onClick: () => onHitClick(hit.objectID),
    }))

  return <TextResults title="Suggestions" results={results} />
}
