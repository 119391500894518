import { ListItem } from './TextResult.styles'
import { AnchorTextResult } from './types'

interface AnchorResultProps {
  result: AnchorTextResult
}

export const AnchorResult = ({ result }: AnchorResultProps): JSX.Element => {
  return (
    <ListItem>
      <a href={result.href}>{result.label}</a>
    </ListItem>
  )
}
