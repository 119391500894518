import { ListItem } from './TextResult.styles'
import { CallbackTextResult } from './types'

interface CallbackResultProps {
  result: CallbackTextResult
}
export const CallbackResult = ({ result }: CallbackResultProps) => {
  return (
    <ListItem id={result.label} onClick={result.onClick} key={result.label}>
      {result.label}
    </ListItem>
  )
}
